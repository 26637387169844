import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Navbar from "./header/Navbar"
import NavItem from "./header/NavItem"
import Submenu from "./header/Submenu"
import SearchMenu from "./header/SearchMenu"
import Login from "./header/Login"
import SupportBtn from "./header/SupportBtn"
import SearchButton from "./header/SearchButton"
import BurgerButton from "./header/BurgerButton"
import BurgerMenu from "./header/BurgerMenu"
import BurgerMenuItem from "./header/BurgerMenuItem"
import MetaNavItem from "./header/MetaNavItem"
import BurgerSubMenu from "./header/BurgerSubMenu"
import BurgerSubMenuItem from "./header/BurgerSubMenuItem"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { set } from "lodash"

const Header = ({ navigation, banner }) => {
  const [menuState, setmenuState] = useState(null)
  const [searchIsOpen, setSearchIsOpen] = useState(false)
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false)
  const [burgerSubMenuIsOpen, setBurgerSubMenuIsOpen] = useState(false)
  const [burgerSubMenuItem, setBurgerSubMenuItem] = useState(null)
  const [isMobile, setIsMobile] = useState(false);

  const menuClickHandler = (state) => {
    searchIsOpen && setSearchIsOpen(false)
    burgerMenuIsOpen && setBurgerMenuIsOpen(false)
    setBurgerSubMenuItem(null);
    menuState === state ? setmenuState(null) : setmenuState(state)
  }

  const toggleSearch = () => {
    menuState !== null && setmenuState(null)
    setSearchIsOpen((value) => !value)
    setBurgerMenuIsOpen(false)
    setBurgerSubMenuIsOpen(false)
  }

  const toggleBurgerMenu = () => {
    menuState !== null && setmenuState(null)
    setSearchIsOpen(false)
    setBurgerMenuIsOpen((value) => !value)
    setBurgerSubMenuIsOpen(false)
  }

  const openBurgerSubMenu = (item) => {
    setBurgerSubMenuItem(item);
    setBurgerSubMenuIsOpen(true)
  }

  const closeBurgerSubMenu = () => {
    setBurgerSubMenuIsOpen(false)
  }

  const closeAllMenusHandler = () => {
    setmenuState(null)
    setSearchIsOpen(false)
    setBurgerMenuIsOpen(false)
    setBurgerSubMenuIsOpen(false)
  }

  var mobileNavigation = null;
  var desktopNavigation = null;
  if (navigation) {
    desktopNavigation = structuredClone(navigation);

    // Extend navigation with login, but only for mobile
    // The last navigation item should contain the meta navigation
    // We need a login link between the last navigation item and the meta navigation
    mobileNavigation = structuredClone(desktopNavigation);
    const navItemCount = mobileNavigation.length - 1;
    mobileNavigation[navItemCount + 1] = mobileNavigation[navItemCount];
    mobileNavigation[navItemCount] = {
      "link": {
          "url": "/login",
          "linktype": "story",
          "fieldtype": "multilink",
          "cached_url": "login/"
      },
      "title": "Login",
      "primary": false,
      "component": "nav_item",
      "description": ""
    };
  }

  useEffect(() => {
    if (window && document) {
      gsap.registerPlugin(ScrollTrigger)

      let mainHeader = document.querySelector(".header-main")

      if (mainHeader) {
        let mainOffset = mainHeader.offsetHeight
        let mainElement = document.querySelector("main")

        ScrollTrigger.create({
          start: "top -200",
          end: 99999,
          onEnter: () => {
            mainElement.style.paddingTop = `${mainOffset}px`
            mainHeader.classList.remove("header-main-fixed-show")
            mainHeader.classList.add("header-main-fixed")
            mainHeader.classList.add("header-main-fixed-active")
          },
          onLeaveBack: () => {
            mainElement.style.paddingTop = null
            mainHeader.classList.remove("header-main-fixed")
            mainHeader.classList.remove("header-main-fixed-active")
          },
        })

        // only show when scrolling up
        ScrollTrigger.create({
          start: "top -300",
          end: 99999,
          onUpdate: ({ direction }) => {
            if (direction == -1) {
              mainHeader.classList.add("header-main-fixed-show")
            } else {
              mainHeader.classList.remove("header-main-fixed-show")
            }
          },
        })
      }
      const handleResize = () => {
        if (window.innerWidth < 1024) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      }
      handleResize();
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [])

  return (
    <>
      {(menuState !== null || burgerMenuIsOpen || searchIsOpen) && (
        <Helmet
          htmlAttributes={{
            class: "overflow-hidden",
          }}
          bodyAttributes={{
            class: "theme-default font-body overflow-y-scroll",
          }}
        />
      )}
      <header id="top" className="relative h-mainnav bg-navbg border-b border-gray-200 header-main">
        <Navbar clickHandler={menuClickHandler} className="flex items-center">
          <div className="flex justify-center flex-1">
            {desktopNavigation?.map((item, index) => (
              <NavItem
                key={index}
                // title={item.title}
                // link={item.link}
                // uid={item._uid}
                item={item}
                clickHandler={menuClickHandler}
                menuState={menuState}
              />
            ))}
          </div>
          <div className="flex flex-row flex-no-wrap justify-end items-center gap-2">
            <SearchButton clickHandler={toggleSearch} />
            <Login />
            {isMobile && <BurgerButton clickHandler={toggleBurgerMenu} burgerMenuIsOpen={burgerMenuIsOpen} />}
          </div>
        </Navbar>
        <SearchMenu
          searchState={searchIsOpen}
          clickHandler={menuClickHandler}
          banner={banner}
        />
        <Submenu
          menuState={menuState}
          navigation={desktopNavigation}
          clickHandler={menuClickHandler}
          closeAllMenusHandler={closeAllMenusHandler}
          banner={banner}
        />
        <BurgerMenu
          menuState={burgerMenuIsOpen}
          clickHandler={toggleBurgerMenu}
          banner={banner}
        >
          <ul className="block mb-4">
            {mobileNavigation?.map(
              (item, index) =>
                (item.component === "nav_group" ||
                  item.component === "nav_item") && (
                  <BurgerMenuItem
                    className="burgermenu-items-reveal"
                    key={index}
                    item={item}
                    // title={item.title}
                    // uid={item._uid}
                    clickHandler={menuClickHandler}
                    openBurgerSubMenu={openBurgerSubMenu}
                    menuState={burgerMenuIsOpen}
                    closeAllMenusHandler={closeAllMenusHandler}
                  />
                )
            )}
          </ul>
          <ul className="mx-4 flex flex-col gap-4 my-6 mb-10">
            {mobileNavigation?.map((item) => {
              if (item?.component === "meta_navigation") {
                return item?.content?.map((item, index) => (
                  <MetaNavItem
                    className="burgermenu-items-reveal py-1"
                    key={index}
                    title={item.title}
                    link={item.link}
                    closeAllMenusHandler={closeAllMenusHandler}
                    component={item.component}
                  />
                ))
              }
              return null
            })}
          </ul>
          <br />
        </BurgerMenu>
        <BurgerSubMenu
          menuState={burgerSubMenuIsOpen}
          closeBurgerSubMenu={closeBurgerSubMenu}
          clickHandler={closeAllMenusHandler}
          banner={banner}
          item={burgerSubMenuItem}
        />
      </header>
    </>
  )
}

export default Header
