import React, { useEffect, useContext } from "react"
// import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/Seo"
import Header from "./Header"
import { Helmet } from "react-helmet"
import Footer from "./Footer"
import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav"
import BannerBlock from "../../../../components/blocks/BannerBlock"
import ReturnTopButton from "./ReturnTopButton"
import { useCookiebotConsent, CookiebotServiceId } from "../../../../hooks/useCookiebotConsent"

const Layout = ({ banner, children }) => {
  const { navData } = useContext(NavContext) || { navData: {} }
  const statisticsConsentState = useCookiebotConsent(CookiebotServiceId.STATISTICS);

  useEffect(() => {
    if (document?.location?.pathname === "/storyblok-preview/") {
      setTimeout(() => {
        if (document.querySelector(".cm-btn.cm-btn-success")) {
          document.querySelector(".cm-btn.cm-btn-success").click()
        }
      }, 100)
    }
  })

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: "scroll-smooth",
          lang: "de",
        }}
        bodyAttributes={{
          class: "theme-default font-body w-full h-full",
        }}
      >
        {statisticsConsentState === 'accepted' && (
          <script>
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_KEY}');
          `}
          </script>
        )}
      </Helmet>
      <div id="top"></div>
      <BannerBlock banner={banner} />
      <Header
        navigation={navData?.mainNavigation?.navigation}
        banner={banner}
      />
      <main>
        {children}
        <ReturnTopButton />
      </main>

      <Footer navigation={navData?.footer?.navigation} />
    </>
  )
}

export default Layout
