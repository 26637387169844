import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { gsap } from "gsap"
import BurgerSubMenuItem from "./BurgerSubMenuItem";

const BurgerSubMenu = ({
  item,
  menuState,
  className,
  closeBurgerSubMenu,
  clickHandler,
  closeAllMenusHandler,
  banner,
}) => {
  const [burgerComp, setBurgerComp] = React.useState(null);

  useEffect(() => {
    const isTailwindSm = window.matchMedia("(max-width: 640px)").matches
    if (menuState) {
      let tlOpen = gsap.timeline()
      tlOpen.to(
          burgerComp,
          {
            ease: "power1.out",
            duration: 0.5,
            opacity: 1,
            width: "100%" //isTailwindSm ? "100%" : "auto",
          },
          0
      )
    } else {
      let tlClose = gsap.timeline()
      tlClose.to(
          burgerComp,
          {
            opacity: 0,
            width : 0,
          },
          0
      )
    }
  }, [menuState, burgerComp])

  // Subnav items are organized in 3 levels with the first and second level being a group of items
  // Extract one leve of sub items from that structure
  let subnavItems = [item];
  item?.content?.forEach((subitem) => {
    if (subitem.component === "nav_group") {
      subitem?.content?.forEach((subsubitem) => {
        if (subsubitem.component === "nav_item") {
          subnavItems.push(subsubitem);
        }
      });
    } else if (subitem.component === "nav_item") {
      subnavItems.push(subitem);
    }
  });

  return (
      <div
          ref={setBurgerComp}
          className={`burgermenu absolute w-0 ${
              banner ? "h-fullvh-banner" : "h-burgermenu"
          } top-belowMainnav right-0 z-50 flex bg-white overflow-x-hidden overflow-y-auto ${className}`}
      >
        <div className={`w-full h-full ${menuState ? "px-4":""} pt-4 pb-8 mb-8`}>
          <ul className="block mb-4">
            {subnavItems.map((subitem, index) => {
              return (
                <BurgerSubMenuItem
                  className="burgersubmenu-items-reveal"
                  key={index}
                  parentItem={item}
                  item={subitem}
                  menuState={menuState}
                  closeBurgerSubMenu={closeBurgerSubMenu}
                  //clickHandler={menuClickHandler}
                  clickHandler={clickHandler}
                  closeAllMenusHandler={closeAllMenusHandler}
                />
              );
            })}
          </ul>
          <br />
        </div>
      </div>
  )
}

export default BurgerSubMenu
