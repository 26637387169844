import React, { Fragment, useEffect } from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import service from "../../../../../images/mm-service-logo.svg"
import software from "../../../../../images/mm-software-logo.svg"
import data from "../../../../../images/mm-data-logo.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

import { gsap } from "gsap"

const SubmenuContent = ({ nav, index, clickHandler, closeAllMenusHandler }) => {
  useEffect(() => {
    let tlOpen = gsap.timeline()
    const openDuration = 0.5

    tlOpen.to(
      ".logo-reveal",
      {
        delay: 0.25,
        ease: "power1.out",
        duration: 0.25,
        opacity: 1,
        scale: 1,
        y: 0,
      },
      0
    )
    tlOpen.to(
      ".submenu-col1-item-reveal",
      {
        ease: "power1.out",
        duration: openDuration / nav?.content?.[0].content?.length,
        opacity: 1,
        y: 0,
        stagger: openDuration / nav?.content?.[0].content?.length,
      },
      0
    )
    tlOpen.to(
      ".submenu-col2-item-reveal",
      {
        delay: 0.25,
        ease: "power1.out",
        duration: openDuration / nav?.content?.[1].content?.length,
        opacity: 1,
        y: 0,
        stagger: (-1 * openDuration) / nav?.content?.[1].content?.length,
      },
      0
    )
  }, [nav])

  return (
    <div key={index} className="relative w-full h-full overflow-y-auto">
      <div className="relative block h-full px-4 mx-auto max-w-container lg:px-20">
        <button
          className="absolute right-0 block px-4 py-2 mr-0 text-xl top-5 lg:top-1 lg:mr-16 hover:text-secondary"
          onClick={() => clickHandler(null)}
        >
          <span className="inline-block w-6 h-6">
            <FontAwesomeIcon icon={faTimes} size="1x" className="" />
          </span>
        </button>
        <div className="flex flex-col h-full md:flex-wrap md:flex-row -mx-grid">
          {/*
            Remove image from submenu:
            https://brandung.atlassian.net/browse/MOROM-37

          <div className="flex w-full py-4 md:w-1/3 lg:w-1/2 px-grid">
            <div className="block mt-4 transform scale-75 -translate-y-24 opacity-0 md:mt-28 logo-reveal">
              <Image
                className="block h-32 md:h-24 lg:h-48 "
                image={
                  nav.title.split(" ")[1].toLowerCase() === "service"
                    ? service
                    : nav.title.split(" ")[1].toLowerCase() === "software"
                    ? software
                    : nav.title.split(" ")[1].toLowerCase() === "data"
                    ? data
                    : nav.title.split(" ")[1].toLowerCase() === "magazin"
                    ? service
                    : ""
                }
              />
            </div>
              </div>
          <div className="flex w-full py-4 md:w-2/3 lg:w-1/2 px-grid"></div>*/}
          <div className="flex w-full py-4 px-grid">
            <div className="block md:mt-6">
              <div className="flex flex-row flex-wrap -mx-grid">
                {nav?.content?.map((col, index) =>
                  index === 0 ? (
                    <div key={index} className="flex flex-col w-1/2 px-grid">
                      {col?.content?.map((item, index) => (
                        <Fragment key={index}>
                          {item.component === "nav_block_title" ? (
                            <span className="block mb-4 font-bold text-gray-400 transform translate-y-12 opacity-0 submenu-col1-item-reveal">
                              {item.title}
                            </span>
                          ) : item.component === "nav_spacer" ? (
                            <span className="block h-6 transform translate-y-12 opacity-0 submenu-col1-item-reveal" />
                          ) : (
                            <Link
                              key={index}
                              className="block mb-6 transform translate-y-12 opacity-0 group submenu-col1-item-reveal"
                              link={`/${item.link.cached_url}`}
                              onClick={closeAllMenusHandler}
                            >
                              <span className="block text-sm font-bold uppercase sm:text-base hyphens-auto group-hover:text-secondary">
                                {item.title}
                              </span>
                              {item.description !== "" && (
                                <span className="block text-sm leading-6 hyphens-auto">
                                  {item.description}
                                </span>
                              )}
                            </Link>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  ) : (
                    index === 1 && (
                      <div key={index} className="flex flex-col w-1/2 px-grid">
                        {col?.content?.map((item, index) => (
                          <Fragment key={index}>
                            {item.component === "nav_block_title" ? (
                              <span className="block mb-4 font-bold text-gray-400 transform translate-y-4 opacity-0 submenu-col2-item-reveal">
                                {item.title}
                              </span>
                            ) : item.component === "nav_spacer" ? (
                              <span className="block h-6 transform translate-y-12 opacity-0 submenu-col2-item-reveal" />
                            ) : (
                              <Link
                                key={index}
                                className={`block ${
                                  item.description !== "" ? "mb-6" : ""
                                } transform -translate-y-4 opacity-0 group submenu-col2-item-reveal`}
                                link={`/${item.link.cached_url}`}
                                onClick={closeAllMenusHandler}
                              >
                                <span className="block text-sm font-bold uppercase sm:text-base hyphens-auto group-hover:text-secondary">
                                  {item.title}
                                </span>
                                {item.description !== "" && (
                                  <span className="block text-sm leading-6 hyphens-auto">
                                    {item.description}
                                  </span>
                                )}
                              </Link>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    )
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmenuContent
