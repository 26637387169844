import React from "react"
import Search from "../Search"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

const SearchMenu = ({ searchState, clickHandler, banner }) => {
  return (
    <div
      className={`searchmenu absolute left-0 z-50 block w-full ${
        banner ? "h-fullvh-banner-submenu" : "h-fullvh-submenu "
      } bg-gray-50 top-belowMainnav transform transition-all ease-out duration-500 ${
        searchState ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div className="relative block w-full h-full px-10 mx-auto overflow-y-scroll max-w-container lg:px-20">
        <button
          className="absolute right-0 block px-4 py-2 ml-auto mr-4 text-xl top-1 lg:mr-16 hover:text-secondary"
          onClick={() => clickHandler(null)}
        >
          <span className="inline-block w-6 h-6">
            <FontAwesomeIcon icon={faTimes} size="1x" className="" />
          </span>
        </button>
        <div className="mt-16 ">
          <Search searchTerm="Suchen..." minTermLength="3" maxResults="10" />
        </div>
      </div>
    </div>
  )
}

export default SearchMenu
