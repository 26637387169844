import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { gsap } from "gsap"

const BurgerMenu = ({
                      menuState,
                      className,
                      children,
                      clickHandler,
                      closeAllMenusHandler,
                      banner,
                    }) => {
  const [burgerComp, setBurgerComp] = React.useState(null)
  useEffect(() => {
    const isTailwindSm = window.matchMedia("(max-width: 640px)").matches
    if (menuState) {
      let tlOpen = gsap.timeline()
      tlOpen.to(
          burgerComp,
          {
            ease: "power1.out",
            duration: 0.5,
            opacity: 1,
            width: "100%" //isTailwindSm ? "100%" : "auto",
          },
          0
      )
    } else {
      let tlClose = gsap.timeline()
      tlClose.to(
          burgerComp,
          {
            opacity: 0,
            width : 0,
          },
          0
      )
    }
  }, [menuState, burgerComp])

  return (
      <div
          ref={setBurgerComp}
          className={`burgermenu absolute w-0 ${
              banner ? "h-fullvh-banner" : "h-burgermenu"
          } top-belowMainnav right-0 z-50 flex bg-white overflow-x-hidden overflow-y-auto ${className}`}
      >
        <div className={`w-full h-full ${menuState ? "px-4":""} pt-4 pb-8`}>
          {children}
        </div>
      </div>
  )
}

export default BurgerMenu
