import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons"

const BurgerButton = ({ clickHandler, burgerMenuIsOpen }) => {
  return (
    <div className="">
      <button
        className="w-10 h-10 flex items-center justify-center border border-primary text-xl cursor-pointer md:block hover:text-secondary"
        onClick={() => clickHandler()}
      >
        <span className="inline-block w-6 h-6">
          { burgerMenuIsOpen
            ? <FontAwesomeIcon icon={faTimes} size="1x" className="text-primary" />
            : <FontAwesomeIcon icon={faBars} size="1x" className="text-primary" />
          }
        </span>
      </button>
    </div>
  )
}

export default BurgerButton
