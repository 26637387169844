import React from "react"
import { Link } from "gatsby"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import logoMobile from "../../../../../images/mm-logo-blue-noclaim.svg"

const Logo = ({ clickHandler }) => {
  let isStart = null

  const checkStart = ({ isCurrent }) => {
    isStart = isCurrent
  }

  return (
    <span
      className="px-grid"
      onClick={() => isStart && clickHandler(null)}
    >
      <Link getProps={checkStart} to="/">
        <Image
          className="inline-block h-8 xl:h-9 xl:w-full"
          image={{
            filename: logoMobile,
            alt: "Morgen und Morgen Logo",
            title: "Morgen und Morgen Logo",
          }}
        />
      </Link>
    </span>
  )
}

export default Logo
