import React, { useEffect, useLayoutEffect } from "react"
import { gsap } from "gsap"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import backIcon from "../../../../../images/burgermenu-subnav-close.svg"

const BurgerSubMenuItem = ({ item, parentItem, closeBurgerSubMenu, clickHandler, className, menuState, closeAllMenusHandler }) => {
  useEffect(() => {
    if (item !== null) {
      if (menuState) {
        let tlOpen = gsap.timeline()

        tlOpen.to(
          ".burgersubmenu-items-reveal",
          {
            ease: "power1.out",
            duration: 0.75,
            opacity: 1,
            x: 0,
            stagger: 0.05,
          },
          0
        )
      } else {
        let tlClose = gsap.timeline()
        tlClose.to(
          ".burgersubmenu-items-reveal",
          {
            opacity: 0,
            x: "100%",
            stagger: 0.05,
          },
          0
        )
      }
    }
  }, [item, menuState])

  if (item === null) {
    return <></>
  }

  if (item.component === "nav_group") {
    // Only one level of sub items is supported
    if (item !== parentItem) {
      return <></>
    }
    return (
      <li className={`opacity-0 transform translate-x-full whitespace-nowrap ${className}`}>
        <button
          className="w-full mb-2 border-b border-gray-300 p-4 text-lg leading-snug text-left uppercase cursor-pointer font-display hover:text-secondary"
          onClick={() => closeBurgerSubMenu()}
        >
          <div className="flex items-center h-6 gap-1">
            <Image
              className="inline-block w-6 h-6 text-primary hover:text-secondary"
              image={{
                filename: backIcon,
                alt: "Zurück",
                title: "Zurück",
              }}
            /> {item.title}
          </div>
        </button>
      </li>
    )
  } else if (item.component === "nav_item") {
    return (
      <li className={`opacity-0 transform translate-x-full ${className}`}>
        <Link
          link={item.link}
          className="inline-block w-full p-4 text-lg leading-snug text-left cursor-pointer font-display text-primary hover:text-secondary"
          onClick={closeAllMenusHandler}
        >
          <div className="font-bold uppercase">{item.title}</div>
          {item.description && <div className="text-sm mt-2">{item.description}</div>}
        </Link>
      </li>
    )
  } else {
    return <></>
  }
}

export default BurgerSubMenuItem
